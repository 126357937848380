import { createStyles } from '@mantine/core';
import { forwardRef } from 'react';

const useStyles = createStyles((theme) => ({
  root: {
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '0 20px',
    backgroundColor: '#F6FAFB',
    ...theme.other.hideScrollBar,
  },
}));

export default forwardRef(function MessageList({ children, className }, ref) {
  const { cx, classes } = useStyles();
  return (
    <div ref={ref} className={cx(classes.root, className)}>
      {children}
    </div>
  );
});
